import React from 'react';
import debounce from 'lodash/debounce';
import {
  Row, Col, Form, Input, Button, Card, Typography, message,
  Image, Dropdown, Menu,
} from 'antd';
import { DownOutlined, HeartFilled } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import superagent from 'superagent';
import { observer, inject } from 'mobx-react';
import sideImage from '../../assets/images/star.png';

const toastKey = 'str';
const { Title } = Typography;
@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      loading: false,
      windowsWidth: 0,
    });

    this.state = this.initialState();
    this.updateDimensions = debounce(() => {
      this.setState({ windowsWidth: window.innerWidth });
    }, 300);
    this.onFinish = (values) => {
      this.setState({ loading: true });
      superagent
        .post(`${process.env.REACT_APP_API_LINK}/auth/login`)
        .send({ username: values.username, password: values.password }) // sends a JSON post body
        .set('Content-Type', 'application/json')
        .type('json')
        .end((err, res) => {
          this.setState({ loading: false });
          if (err) {
            message.error({ content: 'Login Failed!', key: toastKey });
          } else {
            const { body } = res;
            if (body.success) {
              // eslint-disable-next-line react/destructuring-assignment
              this.props.userStore.value = body.data.user;
              // eslint-disable-next-line react/destructuring-assignment
              this.props.sectionsStore.value = body.data.sections;
              // eslint-disable-next-line react/destructuring-assignment
              this.props.tokenStore.value = body.data.token;
            } else {
              message.error({ content: body.msg, key: toastKey });
            }
          }
        });
    };
  }

  render() {
    const { tokenStore, to, i18n } = this.props;
    const { loading, windowsWidth } = this.state;
    if (`${tokenStore.value}`.trim() !== '') {
      return to;
    }
    return (
      <Row
        style={{ position: 'absolute', height: '100%', width: '100%' }}
        type="flex"
        align="middle"
      >
        <Col lg={15} md={12} xs={0} style={{ backgroundColor: '#00315c', height: '100%' }}>
          <div style={{
            position: 'absolute',
            width: '50%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          >
            <Image src={sideImage} />

          </div>
        </Col>
        <Col lg={9} md={12} sm={24} xs={24}>
          <Row>
            <Col span={24}>
              { windowsWidth >= 768 ? (
                <Title align="center" style={{ color: '#EFBA60' }}>
                  {i18n.t('title')}
                </Title>
              ) : null}

              { windowsWidth < 768 ? (
                <Title level={2} align="center" style={{ color: '#EFBA60' }}>
                  {i18n.t('title')}
                </Title>
              ) : null}

            </Col>
            <Col span={24}>
              <Card bordered={false}>
                <Form
                  name="basic"
                  layout="vertical"
                  onFinish={this.onFinish}
                  onFinishFailed={this.onFinishFailed}
                >
                  <Form.Item
                    label="Username"
                    name="username"
                    rules={[
                      {
                        required: true,
                        min: 3,
                        message: 'Minimum length for username is 3 characters!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Row gutter={[10, 10]}>
                    <Col span={8}>
                      <Dropdown
                        overlay={(
                          <Menu>
                            <Menu.Item onClick={() => i18n.changeLanguage('en')} key="1">
                              English
                            </Menu.Item>
                            <Menu.Item onClick={() => i18n.changeLanguage('ku')} key="1">
                              کوردی
                            </Menu.Item>
                            <Menu.Item onClick={() => i18n.changeLanguage('ar')} key="1">
                              العربية
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <Button type="dashed" block>
                          {
                            i18n.language === 'en' ? 'English' : null
                          }
                          {
                            i18n.language === 'ku' ? 'کوردی' : null
                          }
                          {
                            i18n.language === 'ar' ? 'العربية' : null
                          }
                          {' '}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Col>
                    <Col span={10} />
                    <Col span={6}>
                      <Form.Item>
                        <Button
                          block
                          type="primary"
                          htmlType="submit"
                          loading={loading}
                        >
                          {i18n.t('login')}
                        </Button>
                      </Form.Item>
                    </Col>
                    {/* <Button onClick={() => { i18n.changeLanguage('en'); }}>English</Button>
                    <Button onClick={() => { i18n.changeLanguage('ku'); }}>کوردی</Button>
                    <Button onClick={() => { i18n.changeLanguage('ar'); }}>العربية</Button> */}
                  </Row>
                </Form>
              </Card>
            </Col>

            <Col span={24}>
              { windowsWidth < 768 ? (
                <Row
                  style={{ position: 'absolute', height: '100%', width: '100%' }}
                  type="flex"
                  align="middle"
                >
                  <Col span={24}>
                    <Typography.Paragraph align="center">
                      <span>Developed with </span>
                      <HeartFilled style={{ color: 'red' }} />
                      {' '}
                      By&nbsp;
                      <a href="https://lucid-source.com" title="Lucid Source">
                        Lucid Source
                      </a>
                    </Typography.Paragraph>
                  </Col>
                </Row>
              ) : null }
            </Col>
            <Col md={0} xs={24}>
              <Row
                style={{ position: 'absolute', height: '100%', width: '100%' }}
                type="flex"
                align="middle"
              >
                <Col span={24}>
                  <Typography.Paragraph align="center">
                    <span>Developed with </span>
                    <HeartFilled style={{ color: 'red' }} />
                    {' '}
                    By&nbsp;
                    <a href="https://lucid-source.com" title="Lucid Source">
                      Lucid Source
                    </a>
                  </Typography.Paragraph>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

      </Row>
    );
  }
}

export default withTranslation()(Login);
