import { lazy } from 'react';

const options = [
  {
    key: Math.random(),
    path: '/currency-rate',
    component: lazy(() => import('../currency-rate/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/block',
    component: lazy(() => import('../block/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/unit-type',
    component: lazy(() => import('../unit-type/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/garage',
    component: lazy(() => import('../garage/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/car',
    component: lazy(() => import('../car/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/service-list',
    component: lazy(() => import('../service/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/contact-list',
    component: lazy(() => import('../contact/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/contact-person-type',
    component: lazy(() => import('../contact-person-type/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/attachment-type',
    component: lazy(() => import('../attachment-type/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/',
    component: lazy(() => import('../unit/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/service-subscription',
    component: lazy(() => import('../service-subscription/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/payment',
    component: lazy(() => import('../payment/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/expense-list',
    component: lazy(() => import('../expense/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/expense-type',
    component: lazy(() => import('../expense-type/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/safe',
    component: lazy(() => import('../safe/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/report',
    component: lazy(() => import('../report/Main')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/tickets',
    component: lazy(() => import('../tickets/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/worker',
    component: lazy(() => import('../workers/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/settings',
    component: lazy(() => import('../settings/Settings')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/contract',
    component: lazy(() => import('../unit-transaction/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/subscription-pending-payment',
    component: lazy(() => import('../subscription-payment/List')),
    exact: true,
  },
  {
    key: Math.random(),
    path: '/dashboard',
    component: lazy(() => import('../dashboard/Content')),
    exact: true,
  },
];
export default options;
