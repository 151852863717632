import React, { Suspense } from 'react';

import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import {
  Layout, Menu, Button, Col, Popover, Avatar, Image, Dropdown,
} from 'antd';
import {
  HeartFilled, MenuOutlined, UserOutlined, FilePptOutlined,
  AppstoreOutlined, DownOutlined, TagsOutlined, SettingOutlined,
  TransactionOutlined,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoiceDollar, faIdCard, faUsersCog, faHandHoldingUsd, faFileContract,
  faCar, faCalculator, faChartLine,
} from '@fortawesome/free-solid-svg-icons';

import {
  Switch, Route, Link, withRouter,
} from 'react-router-dom';

import debounce from 'lodash/debounce';
import Loading from '../basic/Loading';
import routes from './dashboardRoutes';
import logo from '../../assets/images/StarLogo.png';

const {
  Header, Content, Footer, Sider,
} = Layout;
const { SubMenu } = Menu;

@inject('userStore', 'tokenStore', 'sectionsStore')
@observer
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = () => ({
      collapsed: false,
      windowsWidth: 0,
    });
    this.state = this.initialState();

    this.routeClicked = (history, to) => {
      history.push(to);
    };
    this.toggleSidebar = () => {
      this.setState((prevState) => ({
        collapsed: !prevState.collapsed,
      }));
    };
    this.logout = () => {
      Promise.all([
        // eslint-disable-next-line react/destructuring-assignment
        this.props.userStore.clear(),
        // eslint-disable-next-line react/destructuring-assignment
        this.props.tokenStore.clear(),
        // eslint-disable-next-line react/destructuring-assignment
        this.props.sectionsStore.clear(),
      ]).then(() => {
      });
    };

    this.updateDimensions = debounce(() => {
      this.setState({ windowsWidth: window.innerWidth });
      const { windowsWidth } = this.state;
      if (windowsWidth < 992) {
        this.setState({ collapsed: true });
      } else {
        this.setState({ collapsed: false });
      }
    }, 300);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const { collapsed, windowsWidth } = this.state;
    const { location, i18n } = this.props;
    return (
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          theme="dark"
          style={{
            height: '100vh',
            left: 0,
            position: 'fixed',
            overflow: 'auto',
            background: '#2d3546',
          }}
          width={collapsed ? 0 : 240}
        >
          <div style={{
            width: '100%',
            background: '#1f2532',
            textAlign: 'center',
          }}
          >
            <Image src={logo} width={192} style={{ padding: '6px 0' }} />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[location.pathname]}
            style={{ background: '#2d3646', marginTop: 35 }}
          >
            {/* <Menu.Item key="/dashboard">
              <FontAwesomeIcon icon={faChartLine} style={{ marginRight: 10 }} />
              <span>Dashboard</span>
              <Link to="/dashboard" />
            </Menu.Item> */}

            <Menu.Item key="/">
              <AppstoreOutlined />
              <span>Unit</span>
              <Link to="/" />
            </Menu.Item>

            <Menu.Item key="/service-subscription">
              <FontAwesomeIcon icon={faUsersCog} style={{ marginRight: 10 }} />
              <span>Service</span>
              <Link to="/service-subscription" />
            </Menu.Item>

            <Menu.Item key="/car">
              <FontAwesomeIcon icon={faCar} style={{ marginRight: 10 }} />
              <span>Car</span>
              <Link to="/car" />
            </Menu.Item>

            <Menu.Item key="/payment">
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
                style={{ marginRight: 10 }}
              />
              <span>Invoice</span>
              <Link to="/payment" />
            </Menu.Item>

            <Menu.Item key="/subscription-pending-payment">
              <TransactionOutlined />
              <span>Subscriptions</span>
              <Link to="/subscription-pending-payment" />
            </Menu.Item>

            <SubMenu
              key="accounting"
              icon={(
                <FontAwesomeIcon
                  icon={faCalculator}
                  style={{ marginRight: 10 }}
                />
              )}
              title="Accounting"
            >
              <Menu.Item key="/expense-list">
                <FontAwesomeIcon icon={faHandHoldingUsd} style={{ marginRight: 10 }} />
                <span>Expenses</span>
                <Link to="/expense-list" />
              </Menu.Item>
            </SubMenu>

            <Menu.Item
              key="/tickets"
              icon={<TagsOutlined />}
            >
              <span>Tickets</span>
              <Link to="/tickets" />
            </Menu.Item>

            <Menu.Item key="/contract">
              <FontAwesomeIcon icon={faFileContract} style={{ marginRight: 10 }} />
              <span>Contract</span>
              <Link to="/contract" />
            </Menu.Item>

            <Menu.Item key="/contact-list">
              <FontAwesomeIcon icon={faIdCard} style={{ marginRight: 10 }} />
              <span>Contacts</span>
              <Link to="/contact-list" />
            </Menu.Item>

            <Menu.Item key="/report">
              <FilePptOutlined />
              <span>Report</span>
              <Link to="/report" />
            </Menu.Item>

            <Menu.Item key="/settings">
              <SettingOutlined />
              <span>Settings</span>
              <Link to="/settings" />
            </Menu.Item>

          </Menu>
        </Sider>
        <Layout
          className="site-layout"
          style={{
            transition: 'all 0.2s',
            marginLeft: collapsed ? 0 : 240,
          }}
        >
          <Header
            className="site-layout-background"
            style={{ padding: 0, display: 'inherit' }}
          >
            <Col span={8} style={{ textAlign: 'start', paddingLeft: 10 }}>
              <Button
                type="link"
                icon={
                  <MenuOutlined style={{ fontSize: 15, color: '#1f2532' }} />
                }
                onClick={this.toggleSidebar}
              />
            </Col>
            <Col span={12} />
            <Col span={3}>
              <Dropdown
                overlay={(
                  <Menu>
                    <Menu.Item onClick={() => i18n.changeLanguage('en')} key="1">
                      English
                    </Menu.Item>
                    <Menu.Item onClick={() => i18n.changeLanguage('ku')} key="2">
                      کوردی
                    </Menu.Item>
                    <Menu.Item onClick={() => i18n.changeLanguage('ar')} key="3">
                      العربية
                    </Menu.Item>
                  </Menu>
                        )}
              >
                <Button type="dashed" block>
                  {
                    i18n.language === 'en' ? 'English' : null
                  }
                  {
                    i18n.language === 'ku' ? 'کوردی' : null
                  }
                  {
                    i18n.language === 'ar' ? 'العربية' : null
                  }
                  {' '}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Col>
            <Col
              span={1}
              style={{
                textAlign: 'end',
                paddingRight: 15,
                display: !collapsed && windowsWidth < 420 ? 'none' : undefined,
              }}
            >
              <Popover
                content={(
                  <a href="# " onClick={this.logout}>
                    Logout
                  </a>
                )}
                trigger="hover"
                arrowPointAtCenter
                placement="bottomLeft"
              >
                <Avatar icon={<UserOutlined />} />
              </Popover>
            </Col>
          </Header>
          <Content style={{ margin: '16px 16px', marginBottom: 0 }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 500 }}
            >
              <Suspense fallback={<Loading />}>
                <Switch>
                  {routes.map((route) => (
                    <Route
                      exact={route.exact}
                      key={route.key}
                      path={`${route.path}`}
                    >
                      <route.component />
                    </Route>
                  ))}
                </Switch>
              </Suspense>
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            <span>Developed with </span>
            <HeartFilled style={{ color: 'red' }} />
            {' '}
            By&nbsp;
            <a href="https://lucid-source.com" title="Lucid SOurce">
              Lucid Source
            </a>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default withTranslation()(withRouter((props) => <Dashboard {...props} />));
